import React from "react"
import { graphql } from "gatsby"
import { Col, Image, Row } from "antd"

import "../../styles/category.scss"
import { CategoriesNavbar, CustomLayout, Description } from "../../components"
import defaultImage from "../../../static/default.jpg"

const caetegory = ({ pageContext: { categoryName, id }, data }) => {
  const {
    currencysymbol,
    symbolplace: currencysymbolplace,
    company_name,
    logo,
    categoryList,
    iso_code,
  } = data?.file?.childDataJson || {
    currencysymbol: "",
    symbolplace: 0,
    company_name: "",
    logo: null,
    categoryList: [],
    iso_code: "",
  }

  return (
    <CustomLayout
      companyName={company_name}
      logo={logo ? `${process.env.GATSBY_IMAGE_SERVER_URL}${logo}` : null}
      lang={iso_code?.toLowerCase()}
    >
      <CategoriesNavbar categories={categoryList} selectedCategory={id} />

      <div className="selected__category">
        <div>{categoryName}</div>
      </div>

      <Row justify="space-around" style={{ marginTop: "10px" }}>
        {data.allDataJson.nodes[0].ScreenMenuItems?.map(
          ({ MenuItem, ImageName }) => (
            <Col
              sm={{ span: 11 }}
              xs={{ span: 11 }}
              md={{ span: 7 }}
              lg={{ span: 5 }}
              style={{ marginBottom: "2vh" }}
            >
              <div
                className="card"
                style={{
                  width: "100%",
                  minHeight: "25vh",
                }}
              >
                <Image
                  className="card__image"
                  width={"100%"}
                  src={
                    ImageName
                      ? `${process.env.GATSBY_IMAGE_SERVER_URL}${ImageName}`
                      : defaultImage
                  }
                  alt={MenuItem?.Name}
                  placeholder={
                    <Image
                      className="card__image"
                      width={"100%"}
                      preview={false}
                      src={
                        ImageName
                          ? `${
                              process.env.GATSBY_IMAGE_SERVER_URL
                            }${ImageName?.replace(".png", "-blured-image.png")}`
                          : defaultImage
                      }
                      alt={MenuItem?.Name}
                    />
                  }
                />
                <div className="card__meta">
                  <span className="meta__title">{MenuItem?.Name}</span>
                  <span className="meta__desc">
                    <Description dirty={MenuItem?.Description} />
                  </span>
                </div>

                <Row className="card__portions">
                  {MenuItem?.Portions.map(
                    ({ Name: portionName, Price: portionPrice }) => (
                      <React.Fragment key={`portion-key-${portionName}`}>
                        <Col span={10}>
                          <span className="portion__name">{portionName}</span>
                        </Col>
                        <Col span={14}>
                          {!currencysymbolplace && (
                            <span className="portion__price">
                              {currencysymbol}
                            </span>
                          )}
                          &nbsp;
                          <span className="portion__price">{portionPrice}</span>
                          &nbsp;
                          {!!currencysymbolplace && (
                            <span className="portion__price">
                              {currencysymbol}
                            </span>
                          )}
                        </Col>
                      </React.Fragment>
                    )
                  )}
                </Row>
              </div>
            </Col>
          )
        )}
      </Row>
    </CustomLayout>
  )
}

export const query = graphql`
  query category($categoryName: String) {
    file(relativePath: { eq: "common-Info.json" }) {
      childDataJson {
        logo
        company_name
        iso_code
        currencysymbol
        symbolplace
        categoryList {
          Name
        }
      }
    }
    allDataJson(filter: { Name: { eq: $categoryName } }) {
      nodes {
        ScreenMenuItems {
          ImageName
          MenuItem {
            Name
            Description
            DefaultPrice
            Portions {
              Name
              Price
            }
          }
        }
      }
    }
  }
`
export default caetegory
